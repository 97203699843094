<template>
  <div>
    <v-container fluid class="top pa-0">
      <div
        :class="
          !$vuetify.breakpoint.xsOnly ? 'landing-page-container mx-auto' : ''
        "
      >
        <v-row>
          <v-col
            lg="4"
            md="12"
            sm="12"
            cols="12"
            class="pb-0"
            :class="
              $vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''
            "
          >
            <v-img contain height="180px" class="logo" :src="logo"></v-img>
          </v-col>
          <v-col
            sm="12"
            cols="12"
            class="d-flex justify-center pt-0 flex-column align-center"
          >
            <h1 dark class="white--text font-weight-medium text-center">
              Sta. Cruz Academy of Lubao Inc.
            </h1>

            <blockquote
              class="white--text text-center pa-0"
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'text-headline-1 my-4'
                  : ' blockquote'
              "
            >
              Welcome to the official LMS of Sta. Cruz Academy of Lubao Inc
            </blockquote>

            <v-btn
              width="250"
              depressed
              dark
              class="mt-5 py-6 main-red-bg rounded-pill"
              to="/login"
              >Sign In</v-btn
            >
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-img
              :height="
                $vuetify.breakpoint.smAndDown
                  ? '180'
                  : $vuetify.breakpoint.mdAndDown
                  ? '220'
                  : '300'
              "
              contain
              :class="$vuetify.breakpoint.mdAndDown ? 'my-2' : 'my-4'"
              :src="laptopSVG"
            ></v-img>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <Footer />
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Home",
  },
  name: "LandingPage",
  components: {
    Footer: () => import("@/components/Footer"),
  },
  data() {
    return {
      logo: require("../assets/logo_1.svg"),
      laptopSVG: require("../assets/laptop.svg"),
    };
  },
};
</script>

<style>
* {
  text-decoration: none;
  scroll-behavior: smooth;
}
.top {
  background: url("../assets/lms-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  top: 0;
  width: 100%;
  min-height: 100vh;
}

.blockquote {
  width: 40vw;
  color: white;
}

.logo {
  width: 180px !important;
}

.landing-page-container {
  width: 80vw !important;
}
</style>
